import React from 'react'
import {Component} from 'react'


interface Props {
    item: any
    margin: number
    itemHeight: number
    setViewItem: (item: any) => void
}

interface State {
}

class selectedItem extends Component<Props, State> {
    constructor(props:Props) {
        super(props)
    }
    render() {
        const item = this.props.item
        const hasAspect = item.properties && item.properties.aspect
        const aspect: number = hasAspect ? item.properties.aspect : 0.666666
        return <div className="item" id={item.id} style={{
            display: 'inline-block',
            width: Math.floor(this.props.itemHeight * aspect * 0.5) + 'px',
            height: Math.floor(this.props.itemHeight * 0.5) + 'px',
            margin: this.props.margin / 2 + 'px'
        }}>

        {this.props.item.cover && this.props.item.cover!=='' ? (
            <img src={item.cover} alt={item.title}
                style={{height: '100%', width: '100%'}}
                onClick={this.props.setViewItem.bind(this, item)}
            />
        ) : (
            <div className="nocover" onClick={this.props.setViewItem.bind(this, item)}>
                <div className="bg"></div>
                <div className="textCover">
                    <div className="title">{item.title}</div>
                </div>
            </div>
        )}

        </div>
    }
}
export default selectedItem



import firebase, { db } from './index'

import React from 'react'
import { Component } from 'react'

import Hiradumi from 'hiradumi'

// import items from '../Gifu_Nakatsugawa_plus.json'

import Item from './Item'
import SelectedItem from './SelectedItem'
import ViewItem from './ViewItem'

interface App {
    header: any
    hiradumi: any
    select: any
    selectedItems: any
    selectedItemsContent: any
    scrollTop: number
    startPosition: number
}

interface Props {
    items: any[]
}

interface State {
    loading: boolean
    user: any | null
    selected: boolean
    member: string | null
    items: any[]
    viewItem: any
    openBDInfo: any
    selectedItems: any[]
    width: number
    height: number
    itemMargin: number
    itemHeight: number
    rowRatios: number[] | null
    padding: number
    hideHeader: boolean
    scrollTo: {
        key: string
        value: any
    } | null
}

class App extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            loading: true,
            user: null,
            selected: false,
            member: null,
            items: props.items,
            // items: items,
            viewItem: null,
            // viewItem: items[0],
            openBDInfo: null,
            selectedItems: [],
            width: 100,
            height: 0,
            itemMargin: 10,
            padding: 15,
            itemHeight: document.body.clientWidth > 767 ? 200 : 175,
            rowRatios: document.body.clientWidth > 767 ? [1, 0.9, 0.8, 0.7] : [0.97, 0.85, 0.75, 0.65],
            hideHeader: false,
            scrollTo: null
        }
        this.hiradumi = null
        this.select = null
        this.selectedItems = null
        this.selectedItemsContent = null

    }
    componentDidMount() {

        firebase
            .auth()
            .signInAnonymously()
            .then(() => {
                // Signed in..
            })
            .catch((error) => {
                console.log(error)
            })

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({ user })
                db.collection('favorites')
                    .where('userId', '==', user.uid)
                    .orderBy('created', 'desc')
                    .get()
                    .then((querySnapshot) => {
                        const isbns: string[] = []
                        querySnapshot.forEach((doc) => {
                            isbns.push(doc.data().isbn)
                        })
                        const selectedItems: any[] = []
                        this.state.items.forEach((item) => {
                            if (isbns.includes(item.isbn)) {
                                item.favorite = true
                                selectedItems.push(item)
                            } else {
                                item.favorite = false
                            }
                        })
                        this.setState({ selectedItems: selectedItems, loading: false }, () => {
                            setTimeout(this.setSize.bind(this), 100)
                        })
                    })
                    .catch((error) => {
                        console.log('Error getting documents: ', error)
                    })
            }
        })

        // const member = localStorage.getItem('member')
        // if (member) {
        //     this.setState({ member, selected: true })
        //     this.setStartViewItem()
        // } else {
        //     // document.body.style.overflowY = 'hidden'
        // }
        
        this.setState({ width: window.innerWidth, height: window.innerHeight })

        // iPhoneでピンチでズームすると崩れる対策
        document.body.addEventListener('touchstart', function(e){
            if (e.touches && e.touches.length > 1) e.preventDefault()
        }, {passive: false})
        document.body.addEventListener('touchmove', function(e){
            if (e.touches && e.touches.length > 1) e.preventDefault()
        }, {passive: false})
        const userAgent = navigator.userAgent
        window.addEventListener('resize', () => {
            // iOSのスクロールで、アドレスバーが縮小し、resizeイベントが発生する対策
            if (userAgent.indexOf('iPhone') >= 0 || userAgent.indexOf('iPad') >= 0) return
            setTimeout(this.setSize.bind(this), 100)
            const width = window.innerWidth
            setTimeout(() => {
                if (width !== window.innerHeight) this.setSize()
            }, 500)

        })
        window.addEventListener('orientationchange', () => {
            setTimeout(this.setSize.bind(this), 100)
        })
        window.addEventListener('scroll', this.onScroll.bind(this))

    }

    setStartViewItem(): void {
        if (location.hash !== '') {
            let viewItem = null
            const isbn = location.hash.substr(1)
            this.props.items.some((item) => {
                if (item.isbn === isbn) {
                    viewItem = item
                    return true
                }
            })
            if (viewItem) {
                this.onScrolled = true
                document.body.style.overflowY = 'hidden'
                this.setState({ hideHeader: true, viewItem, scrollTo: {
                    key: 'isbn', value: isbn
                }}, () => {
                    window.scrollTo({top: 600, behavior: 'smooth'})
                })
                setTimeout(() => {
                    this.onScrolled=false
                }, 500)
            }
        }
    }

    setSize(): void {
        let height = window.innerHeight
        if (this.state.hideHeader && this.selectedItemsContent) {
            height -= this.selectedItemsContent.clientHeight + 10
        }
        this.setState({
            width: window.innerWidth,
            height: height,
        })
    }

    // 所属先で、データを変える
    changeItems(): void {
        if (this.state.member==='') {
            fetch('https://nxc.calil.dev/bookfair/all.json').then((r) => r.json()).then((items) => {
                this.setState({items})
            })
        }
    }

    startPosition = 0
    scrollY = 0
    onScrolled = false
    onScroll() {
        if (this.state.member===null) return
        if (this.scrolled) return
        this.scrollY = window.scrollY
        // 下にスクロールされた時
        if (this.scrollY >= this.startPosition) {
            if (this.scrollY >= 100 && this.onScrolled===false) {
                this.onScrolled = true
                document.body.style.overflowY = 'hidden'
                // this.setSize()
                // this.hiradumi.hiradumi.childNodes[0].scrollTop = 2
                this.setState({ hideHeader: true }, () => {
                    window.scrollTo({top: 600, behavior: 'smooth'})
                })
                setTimeout(() => {
                    this.onScrolled=false
                }, 500)
            }
        }
        this.startPosition = this.scrollY
    }

    scrolled = false
    onHiradumiScroll(event: any): void {
        if (this.onScrolled) return
        if (event.scrollOffset < 2 && this.scrolled===false) {
            this.scrolled = true
            setTimeout(() => this.scrolled = false, 500)
            document.body.style.overflowY = 'auto'
            this.setState({ hideHeader: false })
            window.scrollTo({top: 0, behavior: 'smooth'})
            this.setSize()
        }
    }

    onSelect(e: any): void {
        if (e.target.value !== '') {
            this.setState({ selected: true })
        }
    }

    setMember(): void {
        if (this.select.value !== '') {
            this.setState({ member: this.select.value }, this.setSize.bind(this))
            localStorage.setItem('member', this.select.value)
            document.body.style.overflowY = 'auto'
            this.setStartViewItem()
        } else {
            this.setState({ member: null, hideHeader: false, selected: false }, this.setSize.bind(this))
            localStorage.removeItem('member')
            document.body.style.overflowY = 'hidden'
        }
    }

    cancelMember(): void {
        this.setState({ member: null, hideHeader: false, selected: false }, this.setSize.bind(this))
        localStorage.removeItem('member')
        // document.body.style.overflowY = 'hidden'
    }

    vote(item: any): void {
        // console.log(item)
        if (!this.state.member) return alert('学部が選択されていません')
        if (item.favorite) {
            this.state.viewItem.favorite = false
            this.setState({})
            const user = firebase.auth().currentUser
            db.collection('favorites').where('userId', '==', user?.uid).where('isbn', '==', item.isbn).get()
                .then((querySnapshot) => {
                    if (!querySnapshot.empty) {
                        querySnapshot.forEach((docRef) => {
                            db.collection('favorites').doc(docRef.id).delete().then(() => {
                                // console.log('Document successfully deleted!')
                                this.state.items.forEach((i) => {
                                    if (i.isbn === item.isbn) {
                                        i.favorite = false
                                        i.term_popular_count -= 1
                                    }
                                })
                                this.setState({ selectedItems: this.state.selectedItems.filter((i) => i.isbn !== item.isbn) }, this.setSize.bind(this))
                            }).catch((error) => {
                                console.error('Error removing document: ', error)
                            })
                        })
                    }
                })
        } else {
            this.state.viewItem.favorite = true
            this.setState({})
            db.collection('favorites').add({
                isbn: item.isbn,
                userId: firebase.auth().currentUser?.uid,
                member: this.state.member,
                created: firebase.firestore.FieldValue.serverTimestamp()
            })
                .then((docRef) => {
                    // console.log('Document written with ID: ', docRef.id)
                    this.state.items.forEach((i) => {
                        if (i.isbn === item.isbn) {
                            i.favorite = true
                            i.term_popular_count += 1
                        }
                    })
                    this.state.selectedItems.push(item)
                    setTimeout(() => {
                        this.setState({ viewItem: null }, () => {
                            // 右端に追加された本にスクロールする
                            this.selectedItems.scrollTo(10000, 0)
                        })
                    }, 500)
                })
                .catch((error) => {
                    console.error('Error adding document: ', error)
                })
        }
        this.setState({items: this.state.items})
    }

    async setViewItem(viewItem: any): Promise<void> {
        location.hash = viewItem.isbn
        this.setState({ viewItem })
        const openBDInfo = await this.getOpenBDInfo(viewItem)
        // console.log(openBDInfo)
        this.setState({ openBDInfo })
    }

    closeViewItem():void {
        history.pushState('', document.title, window.location.pathname + window.location.search)
        this.setState({ viewItem: null })
    }

    async getOpenBDInfo(item): Promise<any> {
        const books = await fetch('https://api.openbd.jp/v1/get?isbn=' + item.isbn).then(r => r.json()).catch(error => console.log(error))
        if (books.length > 0) {
            const book = books[0]
            // 紹介・目次
            let description = null
            let descriptionLong = null
            let tableOfContents = null
            const CollateralDetails = book.onix.CollateralDetail.TextContent
            if (CollateralDetails) {
                for (const CollateralDetail of CollateralDetails) {
                    const TextType = CollateralDetail.TextType
                    if (TextType === '02') description = CollateralDetail.Text
                    if (TextType === '03') descriptionLong = CollateralDetail.Text
                    if (TextType === '04') tableOfContents = CollateralDetail.Text
                }
            }
            const maegakinado = typeof book.hanmoto.maegakinado !== 'undefined' ? book.hanmoto.maegakinado : null
            const hastameshiyomi = typeof book.hanmoto.hastameshiyomi !== 'undefined' ? book.hanmoto.hastameshiyomi : null
            // console.log(hastameshiyomi)
            return {
                'description': description,
                'descriptionLong': descriptionLong,
                'tableOfContents': tableOfContents,
                'maegakinado': maegakinado,
                'hastameshiyomi': hastameshiyomi,
            }
        }
        return null
    }

    render() {
        let className = this.state.member ? 'memberSelected' : ''
        className += this.state.hideHeader ? ' hideHeader' : ''
        className += this.state.selectedItems ? ' itemSelected' : ''
        return (
            <div className={className}>
                <header>
                    <div className="popbg">
                        <a href="https://www.lib.oka-pu.ac.jp/archives/7353" className="backLibrary">Webブックフェアの詳細はこちら</a>
                        <h1>
                            <img src="./assets/character@2x.png" className="character" alt="" />
                            <img src="./assets/logo.svg" className="logo" alt="岡山県立大学Webブックフェア" />
                        </h1>
                    </div>
                    <p className="blueRibbon">
                        図書館に入れて欲しい本を選ぼう！<br />
                        {this.state.member ? (
                            <React.Fragment>
                                {this.state.member}の方いらっしゃい！
                                <button onClick={this.cancelMember.bind(this)}>&times;</button>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>対象: 本学学部生、院生</React.Fragment>
                        )}
                    </p>
                    {this.state.member === null ? (
                        <React.Fragment>
                            <h2>あなたの学部を教えてください</h2>
                            <div className="selectBox">
                                <select
                                    ref={(element) => this.select = element}
                                    onChange={this.onSelect.bind(this)}
                                    value={this.state.member}
                                >
                                    <option value="">学部をえらぶ</option>
                                    {/* <option value="公開用テスト">公開用テスト</option> */}
                                    <option disabled>(学部生)</option>
                                    <optgroup label="保健福祉学部">
                                        <option value="看護学科">看護学科</option>
                                        <option value="栄養学科">栄養学科</option>
                                        <option value="保健福祉学科社会福祉学専攻（2年生以上）">保健福祉学科社会福祉学専攻（2年生以上）</option>
                                        <option value="保健福祉学科子ども学専攻（2年生以上）">保健福祉学科子ども学専攻（2年生以上）</option>
                                        <option value="現代福祉学科（1年生）">現代福祉学科（1年生）</option>
                                        <option value="子ども学科（1年生）">子ども学科（1年生）</option>
                                    </optgroup>
                                    <optgroup label="情報工学部">
                                        <option value="情報通信工学科">情報通信工学科</option>
                                        <option value="情報システム工学科">情報システム工学科</option>
                                        <option value="人間情報工学科">人間情報工学科</option>
                                    </optgroup>
                                    <optgroup label="デザイン学部">
                                        <option value="デザイン工学科（2年生以上）">デザイン工学科（2年生以上）</option>
                                        <option value="造形デザイン学科（2年生以上）">造形デザイン学科（2年生以上）</option>
                                        <option value="ビジュアルデザイン学科（1年生）">ビジュアルデザイン学科（1年生）</option>
                                        <option value="工芸工業デザイン学科（1年生）">工芸工業デザイン学科（1年生）</option>
                                        <option value="建築学科（1年生）">建築学科（1年生）</option>
                                    </optgroup>
                                    <option disabled>(院生)</option>
                                    <optgroup label="保健福祉学研究科">
                                        <option value="看護学専攻">看護学専攻</option>
                                        <option value="看護学専攻">栄養学専攻</option>
                                        <option value="看護学専攻">保健福祉学専攻</option>
                                        <option value="看護学専攻">保健福祉科学専攻</option>
                                    </optgroup>
                                    <optgroup label="情報系工学研究科">
                                        <option value="(前)システム工学専攻　電子情報通信工学領域">(前)システム工学専攻　電子情報通信工学領域</option>
                                        <option value="(前)システム工学専攻　機械情報システム工学領域">(前)システム工学専攻　機械情報システム工学領域</option>
                                        <option value="(前)システム工学専攻　人間情報システム工学領域">(前)システム工学専攻　人間情報システム工学領域</option>
                                        <option value="(後)システム工学専攻　電子情報通信工学領域">(後)システム工学専攻　電子情報通信工学領域</option>
                                        <option value="(後)システム工学専攻　機械情報システム工学領域">(後)システム工学専攻　機械情報システム工学領域</option>
                                        <option value="(後)システム工学専攻　人間情報システム工学領域">(後)システム工学専攻　人間情報システム工学領域</option>
                                    </optgroup>
                                    <optgroup label="デザイン学研究科">
                                        <option value="デザイン工学専攻　製品・情報デザイン学領域">デザイン工学専攻　製品・情報デザイン学領域</option>
                                        <option value="デザイン工学専攻　建築・都市デザイン学領域">デザイン工学専攻　建築・都市デザイン学領域</option>
                                        <option value="造形デザイン学専攻　造形デザイン学領域">造形デザイン学専攻　造形デザイン学領域</option>
                                    </optgroup>
                                    <optgroup label="その他">
                                        <option value="教員">教員</option>
                                        <option value="職員">職員</option>
                                        <option value="図書館">図書館</option>
                                    </optgroup>
                                </select>
                                <button disabled={!this.state.selected}
                                    onClick={this.setMember.bind(this)}
                                >次へ</button>
                            </div>
                        </React.Fragment>
                    ) : null}
                </header>
                {this.state.member && this.state.selectedItems.length > 0 ? (
                    <div className="selectedItemsContent" ref={(element) => this.selectedItemsContent = element}>
                        <h2 style={{
                            marginLeft: this.state.padding + 5 + 'px'
                        }}>投票した本 - {this.state.selectedItems.length}冊</h2>
                        <div className="selectedItemsWrap" ref={(element) => this.selectedItems = element}>
                            <div className="selectedItems" style={{
                                paddingLeft: this.state.padding + 'px',
                                paddingRight: this.state.padding + 'px',
                                paddingTop: 0
                            }}>
                                {this.state.selectedItems.map((item) => <SelectedItem setViewItem={this.setViewItem.bind(this)}
                                    item={item}
                                    itemHeight={this.state.itemHeight}
                                    margin={this.state.itemMargin}
                                />)}
                            </div>
                        </div>
                        {this.state.selectedItems.length >= 1 ? (
                            <a href="https://forms.gle/xRLKtkH7VvMA8mRF6" target="_blank" style={{
                                marginLeft: this.state.padding + 5 + 'px',
                                marginRight: this.state.padding + 5 + 'px'
                            }}>
                                投票ありがとうございました! アンケートにご協力ください
                            </a>
                        ) : null}
                    </div>
                ) : null}
                {(() => {
                    if (!this.state.loading && this.state.user && this.state.member) {
                        return null
                    }
                })}
                {(() => {
                    if (!this.state.loading) {
                        return <Hiradumi
                            ref={(element: any) => this.hiradumi = element}
                            items={this.state.items}
                            width={this.state.width}
                            height={this.state.height}
                            itemHeight={this.state.itemHeight}
                            itemMargin={this.state.itemMargin}
                            padding={this.state.padding}
                            rowCount={Infinity}
                            rowRatios={this.state.rowRatios}
                            className={"hiradumi"}
                            // sortKey={"term_popular_count"}
                            sortKey={null}
                            onScroll={this.onHiradumiScroll.bind(this)}
                            itemComponent={(props: any) => <Item setViewItem={this.setViewItem.bind(this)} {...props} />}
                             footerComponent={() => {
                                 return (<footer>
                                     <a href="https://forms.gle/vQE95NJhx9GmvqWM7" target="_blank">
                                         ここにない本のリクエストはこちらから
                                     </a>
                                 </footer>)
                             }}
                             footerHeight={75}
                            style={{
                                overflow: this.state.hideHeader ? 'auto' : 'hidden',
                                willChange: 'transform, opacity'
                            }}
                            scrollTo={this.state.scrollTo}
                        />
                    }
                })()}
                {this.state.viewItem ? (
                    <ViewItem
                        viewItem={this.state.viewItem}
                        openBDInfo={this.state.openBDInfo}
                        vote={this.vote.bind(this)}
                        close={this.closeViewItem.bind(this)}
                    />
                ) : null}
            </div>
        )
    }
}

export default App



import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import "firebase/analytics";
// import "firebase/storage";


import firebaseConfig from '../firebaseConfig.json'

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
// if (location.hostname === 'localhost' || location.hostname.match(/192\.168\.0\.\w+$/)) {
//     db.useEmulator('localhost', 8080);
// }
// export const storage = firebase.storage();
// export const analytics = firebase.analytics();

export default firebase

import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

fetch('https://bookdata-fair.calil.dev/all.json').then((r) => r.json()).then((r) => {
    ReactDOM.render(<App items={r} />, document.getElementById('app'));
}).catch(() => {
    alert('データの読み込みに失敗しました。')
})


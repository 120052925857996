import React from 'react'
import {Component} from 'react'


interface Props {
    item: any
    margin: number
    setViewItem: (item: any) => void
}

interface State {
}

class Item extends Component<Props, State> {
    constructor(props:Props) {
        super(props)
    }
    render() {
        const item = this.props.item
        const fontSize = (this.props.item.width - this.props.margin) / 10
        return <React.Fragment>
            {item.term_popular_count && item.term_popular_count>0 ? (
                <span className="popular">{item.term_popular_count}</span>
            ) : null}
            
            {this.props.item.cover && this.props.item.cover!=='' ? (
                <img src={item.cover} alt={item.title}
                    style={{height: '100%', width: '100%'}}
                    onClick={this.props.setViewItem.bind(this, item)}
                />
            ) : (
                <div className="nocover" onClick={this.props.setViewItem.bind(this, item)}>
                    <div className="bg"></div>
                    <div className="textCover">
                        {item.height > 100 ? (
                            <React.Fragment>
                                <div className="title" style={{ fontSize: fontSize + 'px' }}>{item.title}</div>
                                <div className="author" style={{ fontSize: fontSize * 0.7 + 'px' }}>{item.author}</div>
                            </React.Fragment>
                        ) : null}
                    </div>
                </div>
            )}

        </React.Fragment>
    }
}
export default Item



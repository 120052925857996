import React from 'react'
import ISBN from 'isbn3'
import Item from './Item'

const ViewItem = ({ viewItem, openBDInfo, vote, close }) => {
    const fontSize = 10
    return <div className="viewItem">
        <div className="bgWrap" onClick={close}>
        </div>
        <div className="itemWrap">
            <div className="close" onClick={close}>
                <img src="./assets/times.svg" alt="" />
                閉じる
            </div>
            <div className="item">
                {/* <a href={'https://calil.jp/book/' + viewItem.isbn} target="_blank"> */}
                {/* <a href={'https://www.amazon.co.jp/gp/product/B095YBJ57D/' + ISBN.asIsbn10(viewItem.isbn)} target="_blank"> */}
                {viewItem.cover && viewItem.cover!=='' ? (
                    <img src={viewItem.cover} alt="" />
                ) : (
                    <div className="nocover">
                        <div className="bg"></div>
                        <div className="textCover">
                        <React.Fragment>
                            <div className="title" style={{ fontSize: fontSize + 'px' }}>{viewItem.title}</div>
                            <div className="author" style={{ fontSize: fontSize * 0.7 + 'px' }}>{viewItem.author}</div>
                        </React.Fragment>
                        </div>
                    </div>
                )}


                {/* </a> */}
                {viewItem.favorite ? (
                    <button className="voted" onClick={() => vote(viewItem)}>
                        <img src="./assets/check.svg" alt="" />
                        投票しました
                    </button>
                ) : (
                    <button onClick={() => vote(viewItem)}>投票する</button>
                )}
                <div className="info">
                    <div className="title">
                        {viewItem.title}
                    </div>
                    <div className="author">
                        {viewItem.author}
                    </div>
                    {viewItem.publisher ? (
                        <div className="publisher">
                            {viewItem.publisher}
                        </div>
                    ) : null}

                    {openBDInfo ? (
                        <React.Fragment>
                            {openBDInfo.hastameshiyomi ? (
                                <a className="tameshiyomi" href={`http://hanmoto.tameshiyo.me/${viewItem.isbn}`} target="_blank">試し読み</a>
                            ) : null}
                            {openBDInfo.description || openBDInfo.descriptionLong ? (
                                <div className="description">
                                    <h4>内容紹介</h4>
                                    <p>
                                        {openBDInfo.description || openBDInfo.descriptionLong}
                                    </p>
                                </div>
                            ) : null}
                            {openBDInfo.tableOfContents ? (
                                <div className="mokuji">
                                    <h4>目次</h4>
                                    <p>
                                        {openBDInfo.tableOfContents}
                                    </p>
                                </div>
                            ) : null}
                        </React.Fragment>
                    ) : null}

                    <div className="isbn">
                        ISBN: {viewItem.isbn}
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default ViewItem